.MarginContainer {
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: rgb(44, 44, 138);
}

.ProjectTitleBorderDiv:after {
    content: '\200b';
}