.SectionOneContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    /* margin-top: 10%; */
    box-sizing: border-box;
}

.LeftSide {
    box-sizing: border-box;
    padding-top: 10%;
    width: 100%;
    height: 100%;
    background-color: rgb(44, 44, 138);
}

.RightSide {
    width: 40%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
}

.HelloAndTitle {
    height: 40%;
}

.HelloText {
    text-align: center;
    margin-left: 10%;
    width: 30%;
    height: 10%;
    background-color: aliceblue;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.TitleContainer {
    font-family: 'Ubuntu', sans-serif;
    font-size: 5rem;
    text-align: center;
    width: 100%;
}

.TitleContainer p {
    margin-top: 0;
    margin-bottom: 0;
    color: aliceblue;
}

.LocationLogo {
    font-size: 24px;
}

.SectionOneInfoContainer {
    background-color: aliceblue;
    height: 60%;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.Iam {
    text-align: center;
    margin-left: 10%;
    margin-top: 5%;
    height: 10%;
    width: 20%;
    background-color: rgb(44, 44, 138);
    color: aliceblue;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ReactTitle {
    font-size: 3rem;
    margin-top: 0;
    margin-bottom: 0;
}

.Location {
    display: flex;
    justify-content: center;
    align-items: center;
}

.SectionOneLinks {
    display: flex;
    padding-top: 5%;
    justify-content: center;
    align-items: center;
}

.SectionOneLinks a {
    padding: 0 1%;
}

.Logo {
    height: 32px;
    width: 32px;
    font-size: 36px;
    margin: 0 1%;
}

.material-icons:visited {
    color: black;
    background-color: black;
    font-size: 48px;
}

.material-icons:active {
    color: black;
    background-color: black;
}

/*Right Side*/

.ProfileContainer {
    display: flex;
    height: 100%;
    width: 445px;
    position: relative;
    overflow: hidden;
}

.SectionOneProfileMain {
    height: 100%;
    width: 100%;
    position: absolute;
    filter: grayscale(0%);
}
.SectionOneProfileFilter1 {
    margin-left: 4%;
    height: 100%;
    width: 100%;
    position: absolute;
    filter: grayscale(40%);
}
.SectionOneProfileFilter2 {
    margin-left: 6%;
    height: 100%;
    width: 100%;
    position: absolute;
    filter: grayscale(60%) blur(1px);
}
.SectionOneProfileFilter3 {
    margin-left: 8%;
    height: 100%;
    width: 100%;
    position: absolute;
    filter: grayscale(80%) blur(2px);
}

.SectionOneProfileFilter4 {
    margin-left: 10%;
    height: 100%;
    width: 100%;
    position: absolute;
    filter: grayscale(100%) blur(3px);
}

@media (max-width: 1200px) {
    .RightSide {
        height: 95%;
    }

    .HelloAndTitle {
        height: 55%;
    }

    .SectionOneInfoContainer {
        height: 45%;
    }
}


@media (max-width: 1100px) {
    .RightSide {
        height: 90%;
    }
}

@media (max-width: 900px) {
    .RightSide {
        height: 80%;
    }
}

@media (max-width: 800px) {
    .SectionOneContainer {
        flex-direction: column;
    }

    .RightSide {
        display: none;
    }

    .TitleContainer {
        font-size: 4.5rem;
    }

    .HelloAndTitle {
        height: 40%;
    }

    .SectionOneInfoContainer {
        height: 60%;
    }
}

@media (max-width: 600px) {
}

@media (max-width: 400px) {
}