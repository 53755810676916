.fade-sectionOneFirstText-enter-active {
    animation: comeInSectionOneFirstText 0.4s ease-out forwards;
}
.fade-sectionOneFirstText-exit-active {
    animation: goOutSectionOneFirstText 0.4s ease-out forwards;
}
@keyframes comeInSectionOneFirstText {
    0% {
        opacity: 0;
        transform: translateY(-50%);
    }
    50% {
        opacity: 0.8;
        transform: translateY(10%);
    }
    100% {
        opacity: 1;
        transform: translateY(0%);
    }
}
@keyframes goOutSectionOneFirstText {
    0% {
        opacity: 1;
        transform: translateY(0%);
    }
    50% {
        opacity: 0.8;
        transform: translateY(-10%);
    }
    100% {
        opacity: 0;
        transform: translateY(50%);
    }
}

/* .fade-sectionOneSecondText-enter-active {
    animation: comeInSectionOneSecondText 0.4s ease-out forwards;
}
.fade-sectionOneSecondText-exit-active {
    animation: goOutSectionOneSecondText 0.3s ease-out forwards;
}
@keyframes comeInSectionOneSecondText {
    0% {
        transform: translateY(-50%);
    }
    50% {
        transform: translateY(10%);
    }
    100% {
        transform: translateY(0%);
    }
}
@keyframes goOutSectionOneSecondText {
    0% {
        transform: translateY(0%);
    }
    50% {
        transform: translateY(-10%);
    }
    100% {
        transform: translateY(50%);
    }
} */