.fade-sectionOneProfileMain-enter-active {
    animation: comeInSectionOneProfileMain 3s ease-out forwards;
}

@keyframes comeInSectionOneProfileMain {
    0% {
        opacity: 0;
        transform: translateX(200%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.fade-sectionOneProfile1-enter-active {
    animation: comeInSectionOneProfile1 2.8s ease-out forwards;
}
@keyframes comeInSectionOneProfile1 {
    0% {
        opacity: 0;
        transform: translateX(160%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.fade-sectionOneProfile2-enter-active {
    animation: comeInSectionOneProfile2 2.6s ease-out forwards;
}
@keyframes comeInSectionOneProfile2 {
    0% {
        opacity: 0;
        transform: translateX(120%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.fade-sectionOneProfile3-enter-active {
    animation: comeInSectionOneProfile3 2.4s ease-out forwards;
}
@keyframes comeInSectionOneProfile3 {
    0% {
        opacity: 0;
        transform: translateX(80%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}

.fade-sectionOneProfile4-enter-active {
    animation: comeInSectionOneProfile4 2.2s ease-out forwards;
}
@keyframes comeInSectionOneProfile4 {
    0% {
        opacity: 0;
        transform: translateX(40%);
    }
    100% {
        opacity: 1;
        transform: translateX(0%);
    }
}