.SectionTwoContainer {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
}

.TechStackContainer {
    height: 100%;
    width: 100%;
    background-color: aliceblue;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.TechStackTitle {
    font-size: 3.5rem;
}

.TechStackTitle p {
    margin-top: 0;
}

.IndividualStack  {
    padding-right: 5%;
}

.IndividualStack p {
    font-size: 1.5rem;
    margin-top: 0;
}

.TechStackIcons {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 5rem;
    background-color: rgb(44, 44, 138);
    border-radius: 25px;
    width: 80%;
    color: aliceblue;
}

.AboutMe {
    color: aliceblue;
    background-color: rgb(44, 44, 138);
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.AboutMeContainer {
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10%;
}

.AboutMeTitle {
    font-size: 3rem;
}

@media (max-width: 1000px) {
    .TechStackTitle {
        font-size: 3.2rem;
    }
    .IndividualStack p {
        font-size: 1.2rem;
    }
    .TechStackIcons {
        font-size: 4.8rem;
    }
    .AboutMeTitle {
        font-size: 2.8rem;
    }
}

@media (max-width: 800px) {
    .TechStackTitle {
        font-size: 3rem;
    }
    .IndividualStack  {
        padding-right: 2%;
    }
    .IndividualStack p {
        font-size: 1rem;
    }
    .TechStackIcons {
        font-size: 4rem;
    }
    .AboutMeTitle {
        font-size: 2.5rem;
    }
}

@media (max-width: 600px) {
    .TechStackTitle {
        font-size: 2.8rem;
    }
    .IndividualStack  {
        padding-right: 0;
    }
    .IndividualStack p {
        font-size: 0.8rem;
    }
    .TechStackIcons {
        font-size: 3.8rem;
    }
    .AboutMeTitle {
        font-size: 2rem;
    }
}

@media (max-width: 400px) {
    .TechStackTitle {
        font-size: 2.5rem;
    }
    .IndividualStack p {
        font-size: 0.6rem;
    }
    .TechStackIcons {
        font-size: 3.5rem;
    }
    .AboutMeTitle {
        font-size: 1.8rem;
    }
}